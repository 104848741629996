import React from 'react';
import PropTypes from 'prop-types';
import style from './footer.module.scss';

export const Footer = (props) => {
  const progressPercentage = props.currentStep / props.totalStep;
  const progressWidth = `${progressPercentage * 100}%`;
  return (
    <footer className={style.Footer}>
      <span className={style.Indicator}>{props.currentStep + 1} de {props.totalStep}</span>
      <div className={style.Progress}>
        <span className={style.CurrentProgress} style={{width: progressWidth}}></span>
      </div>
    </footer>
  );
};


Footer.propTypes = {
  required: PropTypes.bool,
  currentStep: PropTypes.number.isRequired,
  totalStep: PropTypes.number.isRequired,
};

Footer.defaultProps = {
  required: false,
};
