import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import style from './App.module.css';
import {Intro} from './Intro';
import {Questions} from './Questions';
import {Result} from './Result';
// hubspot api
// b972c43f-9cb7-4253-8701-290ffd507f0d

// Google Client Id
// 700516930570-8lrg8pd1fsi90idcbujcga0q9ifbnkat.apps.googleusercontent.com

// Google Client Secret
// WAtTIwPtUa582jWKvdGVWikK

// Google apikey
// AIzaSyC93YOrB7_R70clfWpY8yw3S7hwHyh5Egg
function App() {
  return (
    <div className={style.App}>
      <Router>
        <main className={style.Body}>
          <Switch>
            <Route exact path="/resultado" component={Result}/>
            <Route exact path="/perguntas/:id?" component={Questions}/>
            <Route exact path="/" component={Intro}/>
            <Route path="*">
              <Redirect to="/"/>
            </Route>
          </Switch>
        </main>
      </Router>
    </div>
  );
}

export default App;
