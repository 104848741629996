/**
 * dependencies
 */
import React from 'react';
import style from './Result.module.css';
import {Link} from 'react-router-dom';
import {HeartRate, MetabolicDatas} from './HeartRate';

const MAX_HR = 220;

export class Result extends React.Component {
  constructor(props) {
    super(props);
    const allowedKeys = ['name', 'bpm', 'birthday', 'effort_target', 'weight', 'gender'];
    const historyState = props.location.search
      .substr(1)
      .split('&')
      .reduce((stateObject, property) => {
        const [key, value] = property.split('=');
        if (allowedKeys.indexOf(key) >= 0) {
          stateObject[key] = decodeURIComponent(value);
        }
        return stateObject;
      }, {});

    this.state = {
      effort_target: localStorage.getItem('effort_target'),
      ...historyState
    };
  }

  render() {
    const map = {
      '40_50': 'ZONA DE ESFORÇO MÍNIMO E INDIVÍDUOS COM ALGUMA RESTRIÇÃO MÉDICA',
      '50_60': 'ZONA DE ESFORÇO MUITO LEVE - TREINO DE REABILITAÇÃO OU REGENERATIVO',
      '60_70': 'ZONA DE ESFORÇO LEVE - TREINO CONFORTÁVEL',
      '70_80': 'ZONA DE TREINAMENTO MODERADO - ESFORÇO MODERADO',
      '80_90': 'ZONA DE TREINAMENTO DE ELEVADA INTENSIDADE - ESFORÇO INTENSO',
      '90_100': 'ZONA DE TREINAMENTO DE ELEVADA INTENSIDADE - ESFORÇO INTENSO',
    };
    const effortTarget = this.state.effort_target || '80_90';
    const [minEffort, maxEffort] = effortTarget.split('_');
    const {minBpm, maxBpm, bpm} = HeartRate(this.state.birthday, this.state.bpm, minEffort, maxEffort);
    const weight = +this.state.weight;
    const {bodySurface, tmb} = MetabolicDatas(this.state.gender, weight);
    //
    return (
      <div className={style.Result}>
        <h2 className={style.Title}>
          Muito obrigado pelas informações, <b>{this.state.name}</b>
        </h2>
        <div className={style.Description}>
          De acordo com os dados, a zona alvo de treinamento adequado sugerido é:
          <strong className={style.Zone}>
            {map[effortTarget]}
          </strong>
          <table className={style.Table} cellPadding="0" cellSpacing="0">
            <tbody>
            <tr>
              <td>Superfície Corporal</td>
              <td>{bodySurface} m²</td>
            </tr>
            <tr>
              <td>Metabolismo de Repouso</td>
              <td>{tmb} kcal</td>
            </tr>
            <tr>
              <td>Frequência cardíaca em repouso</td>
              <td>{bpm} bpm</td>
            </tr>
            <tr>
              <td>Frequência cardíaca mínima</td>
              <td>{minBpm} bpm</td>
            </tr>
            <tr>
              <td>Frequência cardíaca máxima</td>
              <td>{maxBpm} bpm</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colSpan={2} className={style.Logo}>
                <img src="logo-limit.png" alt="Oferecimento Limit Training"/>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                  <span className={style.AuthorBlock}>
                    <span className={style.Author}><br/>Personal James Sampaio</span>
                    <span className={style.Author}>Equipe LimitTraining</span>
                  </span>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>

        <Link className={style.Back} to="/">Voltar</Link>
      </div>
    );
  }
}
