export const HeartRate = (birthday, bpm, min, max) => {
  const [localDay, localMonth, localYear] = birthday.split('/');
  const bithDate = new Date(localYear, localMonth - 1, localDay);
  const now = (new Date).getTime();
  const dateDiff = Math.abs(now - bithDate.getTime());
  const age = Math.ceil(dateDiff / (1000 * 60 * 60 * 24 * 365));
  const base = 220 - age - (+bpm);
  const [minBpm, maxBpm] = [
    Math.ceil(base * (+min * 0.01) + (+bpm)),
    Math.ceil(base * (+max * 0.01) + (+bpm)),
  ];

  return {
    minBpm,
    maxBpm,
    bpm: +bpm,
  };
};

export const MetabolicDatas = (gender, weight) => {
  const bodySurface = Math.pow(weight * weight, 1 / 3) * 0.11;
  const tmb = gender === 'f'
    ? 35 * bodySurface * 24
    : 38 * bodySurface * 24;
  const roundedTmb = tmb.toFixed(2);
  return {
    bodySurface,
    tmb: roundedTmb
  };
};
