/**
 * dependencies
 */
import React from 'react';
import style from './Questions.module.css';
import {Question} from './components/Question/Question';
import {Footer} from './components/Footer/Footer';
import {Redirect} from 'react-router-dom';
import {HeartRate, MetabolicDatas} from './HeartRate';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const maskIgnoreCharacters = [
  '(',
  ')',
  '_',
  '-',
  '/',
];
const maskFillPatterns = [
  '_',
];

const DateRegExp = new RegExp(/^\d{2}\/\d{2}\/\d{4}$/);
const NumericRegExp = new RegExp(/^\d{1,3}$/);
const Validators = {
  notEmpty: (val) => val.trim && val.trim().length > 0,
  dateFormat: (val) => {
    return DateRegExp.test(val);
  },
  numeric: (val) => NumericRegExp.test(val)
};

const questions = [
  {
    label: 'Como posso te chamar ? ',
    field: 'name',
    type: 'text',
    tooltip: 'Nome completo',
    validator: [
      Validators.notEmpty
    ]
  },
  {
    label: 'Sexo',
    type: 'radio',
    field: 'gender',
    tooltip: 'Selecione uma das opções',
    options: [
      {
        id: 'f',
        label: 'Feminino'
      },
      {
        id: 'm',
        label: 'Masculino'
      }
    ],
  },
  {
    label: 'Pode me dizer a data de nascimento ?',
    field: 'birthday',
    type: 'text',
    tooltip: 'No formato de: dia/mês/ano, exemplo: 22/01/1980',
    mask: {
      fill: '_',
      characters: {
        2: '/',
        4: '/'
      },
      maxLength: 10,
    },
    validator: [
      Validators.dateFormat
    ]
  },
  {
    label: 'Quanto está pesando hoje ?',
    field: 'weight',
    type: 'number',
    tooltip: 'Peso em kg',
    validator: [
      Validators.numeric
    ]
  },
  {
    label: 'Quanto está o batimento cardíaco em repouso',
    field: 'bpm',
    type: 'number',
    tooltip: 'Número de batimentos por minuto',
    validator: [
      Validators.numeric
    ]
  },
  {
    label: 'Qual é o objetivo principal do treino ?',
    field: 'objective',
    type: 'text',
    tooltip: 'De maneira resumida, o objeitvo primário e secundário',
    validator: [
      Validators.notEmpty
    ]
  },
  {
    label: 'Nível de esforço desejado no treino',
    type: 'radio',
    field: 'effort_target',
    tooltip: 'Selecione uma das opções acima',
    options: [
      {
        id: '40_50',
        label: '40 à 50% - ZONA DE ESFORÇO MÍNIMO E INDIVÍDUOS COM ALGUMA RESTRIÇÃO MÉDICA'
      },
      {
        id: '50_60',
        label: '50 à 60% - ZONA DE ESFORÇO MUITO LEVE - TREINO DE REABILITAÇÃO OU REGENERATIVO'
      },
      {
        id: '60_70',
        label: '60 à 70% - ZONA DE ESFORÇO LEVE - TREINO CONFORTÁVEL'
      },
      {
        id: '70_80',
        label: '70 à 80% - ZONA DE TREINAMENTO MODERADO - ESFORÇO MODERADO'
      },
      {
        id: '80_90',
        label: '80 à 90% - ZONA DE TREINAMENTO DE ELEVADA INTENSIDADE - ESFORÇO INTENSO'
      },
      {
        id: '90_100',
        label: '90 à 100% - ZONA DE TREINAMENTO DE ESFORÇO MÁXIMO - MUITO INTENSO'
      },
      {
        id: 'undecided',
        label: 'Desconhecido, preciso de orientação',
      },
    ],
  },
  {
    label: 'Desejo um treino elaborado por profissional',
    type: 'checkbox',
    field: 'need_trainer',
    tooltip: 'Selecione uma das opções acima',
    secondaryField: [
      {
        label: 'Número para contato',
        type: 'text',
        name: 'contactNumber',
        key: '0contactNumber'
      }
    ]
  }
];

export class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      questions: questions.map(question => ({
        ...question,
        value: localStorage.getItem(question.field) || '',
        valid: true,
      }))
    };
    this.setValue = this.setValue.bind(this);
    this.setValidState = this.setValidState.bind(this);
    this.submit = this.submit.bind(this);
    this.onCaptchaLoad = this.onCaptchaLoad.bind(this);
    this.onCaptchaVerify = this.onCaptchaVerify.bind(this);
  }

  setValue(value, question, secondary) {
    const updatedQuestions = this.state.questions.map(item => {
      const output = {...item};
      if (question.field === item.field) {
        if (secondary) {
          question.secondaryField[0].value = value;
        } else {
          if (question.mask) {
            const maskCharacters = question.mask.characters;
            const valueParts = value
              .split('')
              .filter(c => maskIgnoreCharacters.indexOf(c) < 0);
            let maskCharacter = null;
            const maskedValueParts = valueParts
              .map((character, key) => {
                if (maskCharacters[key]) {
                  return maskCharacters[key] + character;
                }
                return character;
              });
            output.value = maskedValueParts.join('').substr(0, 10);
            localStorage.setItem(output.field, output.value);
          } else {
            localStorage.setItem(question.field, value);
            output.value = value;
          }
        }
      }
      return output;
    });

    this.setState({
      questions: updatedQuestions
    });
  }


  setValidState(question) {
    const updatedQuestions = this.state.questions.map(item => {
      const output = {...item};
      if (question.field === item.field) {
        const {validator, value} = output;
        const isValid = validator.every(validate => validate(value));
        output.valid = isValid;
      }
      return output;
    });

    this.setState({
      questions: updatedQuestions
    });
  }

  onCaptchaLoad() {
  }

  // Update state after reCaptcha validates visitor
  onCaptchaVerify(token) {
    this.setState({
      token,
      isCaptchaValid: true
    });
  };

  submit(e) {
    e.preventDefault();
    if (this.state.token === '') {
      return alert('Por favor, confirme que você não é um Robo :)');
    }
    const output = this.state.questions.reduce((prev, question) => {
      prev[question.field] = question.value;
      return prev;
    }, {});
    const lastQuestion = this.state.questions[this.state.questions.length - 1];
    const secondaryField = lastQuestion.secondaryField[0];
    const [efforTargetQuestion] = this.state.questions.filter(question => question.field === 'effort_target');
    const [selectedOption] = efforTargetQuestion.options.filter(({id}) => id === output.effort_target);
    const history = this.props.history;
    const queryString = this.state.questions
      .filter(({field}) => field !== 'need_trainer')
      .map((question) => {
        return `${question.field}=${encodeURIComponent(question.value)}`;
      }).join('&');
    const redirectTo = `/resultado?${queryString}`;
    const effortTarget = output.effort_target || '80_90';
    const [minEffort, maxEffort] = effortTarget.split('_');
    const {minBpm, maxBpm, bpm} = HeartRate(output.birthday, output.bpm, minEffort, maxEffort);
    const {bodySurface, tmb} = MetabolicDatas(output.gender, output.weight);
    output.minBpm = minBpm;
    output.maxBpm = maxBpm;
    output.effort_target = selectedOption.label;
    output.contact = secondaryField.value || 'Não informado';
    output.bodySurface = `${bodySurface}  m²`;
    output.tmb = `${tmb} kCal`;
    if (output.need_trainer) {
      output.need_trainer = 'Sim';
    } else {
      output.need_trainer = 'Não';
    }

    console.log(output)

    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': 'trainer',
        'g-recaptcha-response': this.state.token,
        ...output
      })
    }).then((d) => {
      history.push(redirectTo);
    }).catch(error => {
      history.push(redirectTo);
    });
  }

  render() {
    const {setValue, setValidState, submit, state, onCaptchaLoad, onCaptchaVerify} = this;
    const {questions} = state;
    const index = this.props.match.params.id - 1;
    const question = questions[index];

    return !question
      ? (<Redirect to="/resultado"/>)
      : (
        <form className={style.Form} onSubmit={submit} data-netlify-recaptcha="true">
          <input type="hidden" name="form-name" value="trainer"/>
          <div className={style.Questions}>
            <Question
              questions={this.state.questions}
              submit={submit}
              setValue={setValue}
              setValidState={setValidState}
              index={index}
              onCaptchaLoad={onCaptchaLoad}
              onCaptchaVerify={onCaptchaVerify}
            ></Question>
            <Footer currentStep={index} totalStep={questions.length}/>
          </div>
        </form>
      );
  }
}
