/**
 * dependencies
 */
import React from 'react';
import Recaptcha from 'react-recaptcha';
import {Link} from 'react-router-dom';
import style from './Question.module.css';
import classNames from 'classnames';

const FieldDate = ({question, setValue, setValidState}) => (
  <input
    className={classNames([
      style.Field,
      style.FieldDate,
      question.value.length > 0 && style.FieldWithValue,
      !question.valid && style.FieldInvalid,
    ])}
    type="date"
    name={question.field}
    id={question.field}
    value={question.value}
    onBlur={() => setValidState(question)}
    onInput={() => setValidState(question)}
    onChange={e => setValue(e.target.value, question)}
  />
);
const FieldNumeric = ({question, setValue, setValidState}) => (
  <input
    className={classNames([
      style.Field,
      style.FieldNumeric,
      question.value.length > 0 && style.FieldWithValue,
      !question.valid && style.FieldInvalid,
    ])}
    type="number"
    name={question.field}
    id={question.field}
    value={question.value}
    onBlur={() => setValidState(question)}
    onInput={() => setValidState(question)}
    onChange={e => setValue(e.target.value, question)}
  />
);
const FieldText = ({question, setValue, setValidState}) => (
  <input
    className={classNames([
      style.Field,
      style.FieldText,
      question.value.length > 0 && style.FieldWithValue,
      !question.valid && style.FieldInvalid,
    ])}
    type="text"
    name={question.field}
    id={question.field}
    value={question.value}
    onBlur={() => setValidState(question)}
    onInput={() => setValidState(question)}
    onChange={e => setValue(e.target.value, question)}
  />
);

const FieldRadio = ({question, setValue, setValidState}) => (
  <div className={style.FieldRadio}>
    {question.options.map(option => (
      <label htmlFor={option.id} key={option.id} className={style.FieldRadioItem}>
        <span className={style.RadioFieldContainer}>
          <input
            className={style.RadioField}
            type="radio"
            name={question.field}
            id={option.id}
            value={option.id}
            onChange={e => setValue(option.id, question)}
            checked={option.id === question.value}
          />
          <span className={style.RadioStyle}>
            <span className={style.RadioStyleInner}></span>
          </span>
        </span>
        <span className={style.RadioLabel}>{option.label}</span>
      </label>
    ))}
  </div>
);

const FieldCheckbox = ({question, setValue, setValidState}) => {
  const checked = question.value === true || question.value === 'true';
  return (
    <div className={style.FieldCheckbox}>
      <label htmlFor={question.field} className={style.FieldCheckboxItem}>
        <span className={style.CheckboxFieldContainer}>
          <input
            className={style.CheckboxField}
            type="checkbox"
            name={question.field}
            id={question.field}
            checked={checked}
            onChange={e => setValue(e.target.checked, question)}
          />
          <span className={style.CheckboxStyle}>
            <span className={style.CheckboxStyleInner}></span>
          </span>
        </span>
        <span className={style.CheckboxLabel}>{question.label}</span>
      </label>
      {question.secondaryField.map((secondaryField, key) => (
        <div className={style.FieldSecondaryField} key={key}>
          <input
            className={style.FieldSecondaryFieldField}
            id={secondaryField.name}
            type="text"
            name={secondaryField.name}
            onChange={e => setValue(e.target.value, question, true)}
            placeholder={secondaryField.label}
          />
        </div>
      ))}
    </div>
  );
};

const BackButton = ({disabled, index, to}) => index > 0
  ? (
    <Link
      to={to}
      className={classNames(style.Button, style.Back)}
      disabled={disabled}>Voltar
    </Link>
  )
  : null;

/**
 type: 'string'
 type: 'date',
 type: 'number',
 */
const fieldType = {
  date: FieldDate,
  number: FieldNumeric,
  text: FieldText,
  radio: FieldRadio,
  checkbox: FieldCheckbox,
};
export const Question = (props) => {
  const {questions, setValidState, setValue, submit, index} = props;
  const question = questions[index];
  const {field, label, tooltip} = question;
  const DynamicComponent = fieldType[question.type];
  const labelClasses = [style.Label];
  const isLastQuestion = index === questions.length - 1;
  const back = `/perguntas/${props.index}`;
  const next = `/perguntas/${props.index + 2}`;
  const invalid = questions[index].value.length < 1 || !questions[index].valid;
  const handleClick = (e) => {
    if (invalid) {
      e.preventDefault();
    }
  };
  if (questions[index].value.length > 0) {
    labelClasses.push(style.LabelWithValue);
  }

  return (
    <div className={style.Question} title={tooltip || label}>
      <div className={style.FieldBlock}>
        <DynamicComponent
          question={question}
          setValue={setValue}
          setValidState={setValidState}
        ></DynamicComponent>
        <label className={classNames(labelClasses)} htmlFor={field}>{label}</label>
        {invalid
          ? (<span className={style.Info}>Campo obrigatório</span>)
          : (<span className={style.Info}>{tooltip}</span>)
        }
      </div>
      {isLastQuestion
        ? (
          <div className={style.ConfirmWrapper}>
            <Recaptcha
              className={style.Recaptcha}
              sitekey="6LfoXOcUAAAAABeVrUK2O_WwpdPnEXvbyib7fCQY"
              render="explicit"
              verifyCallback={props.onCaptchaVerify}
              hl="pt-BR"
            />
            <div className={style.Actions}>
              <BackButton
                index={props.index}
                to={back}
                disabled={questions[index].value.length < 1 || !questions[index].valid}
              />
              <button
                className={classNames([style.Button])}
                type="submit"
                onClick={submit}
              >
                Enviar
              </button>
            </div>
          </div>
        )
        : (
          <div className={style.Actions}>
            <BackButton
              index={props.index}
              to={back}
              disabled={questions[index].value.length < 1 || !questions[index].valid}
            />
            <Link
              onClick={handleClick}
              to={next}
              className={style.Button}>Enviar</Link>
          </div>
        )
      }
    </div>
  );
};

Question.propTypes = {};
