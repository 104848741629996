/**
 * dependencies
 */
import React from 'react';
import {
  Link
} from 'react-router-dom';
import style from './Intro.module.css';

export class Intro extends React.Component {
  componentDidMount() {
    localStorage.clear();
  }

  render() {
    return (
      <p className={style.Intro}>
        Este formulário irá te ajudar a definir a zona alvo de treinamento.
        <Link to="/perguntas/1" className={style.Button}>Vamos começar ?</Link>
      </p>
    );
  }
};
